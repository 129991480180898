import React from "react";
import { BiLoaderAlt } from 'react-icons/bi'; 
import crossState from "../images/crossstate.jpg";


class Splash extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            data: [],
            is_loaded: false, 
        };
    }

    componentDidMount(){
        fetch('https://crediflow.se/admin/api/splash.php')
        .then(res => res.json())
        .then( json => {
            this.setState({
                is_loaded: true, 
                data: json         
            });
        });
    }

    render(){
        const { is_loaded, data} = this.state;
        return(
            <>
                {!is_loaded ? 
                        <div className="flex justify-center items-center py-10" style={{maxHeight: "560px", maxWidth: "960px"}}>
                            <BiLoaderAlt className="animate-spin w-16 h-16 text-blue-custome" />
                        </div>
                    :
                    <div className="w-full h-full bg-splash bg-top bg-no-repeat bg-cover" style={{maxHeight: "560px", maxWidth: "960px"}}>
                        <div class="w-full p-4 flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col justify-between ">
                                <div className="mb-4 md:mb-0 h-14">
                                    <img src={crossState} alt="crossState"/>
                                </div>
                                <div className="w-full p-4 overflow-x-hidden overflow-y-scrol" style={{height: "350px", maxWidth: "435px", background: "rgba(255, 255, 255, 0.7)", scrollbarWidth: "none"}}>
                                    <h1 className="flex text-lg font-bold">{data[0].title}</h1>
                                    <br/>
                                    <div className="text-gray-800 text-xs">
                                        <div dangerouslySetInnerHTML={{ __html: data[0].body}} />
                                    </div>
                                </div>
                            </div>

                            <div class="w-full p-4 overflow-x-hidden overflow-y-scroll" style={{height: "530px", maxWidth: "480px", background: "rgba(255, 255, 255, 0.7)", scrollbarWidth: "none"}}>
                                <h1 class="flex text-lg font-bold">{data[1].title}</h1>
                                <br/>
                                <div class="text-gray-800 text-xs">
                                    <div dangerouslySetInnerHTML={{ __html: data[1].body}} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}
export default Splash;